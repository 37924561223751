<template>
  <vs-card>
    <div slot="header">
      <vs-row vs-type="flex" vs-align="space-around" vs-w="12">
        <vs-col vs-w="6">
          <h5 class="pt-2">Create Product</h5>
        </vs-col>
        <vs-col vs-w="6">
          <ul style="display:block;text-align:right;">
            <li style="display:inline-block;margin-right:5px;">
              <vs-button style="padding:5px 15px;" @click="onSave()">Save
              </vs-button>

            </li>
            <li style="display:inline-block">
              <vs-button style="padding:4px 15px;" @click="cancelHandler()" type="border">Cancel
              </vs-button>
            </li>
          </ul>
        </vs-col>

      </vs-row>
    </div>
    <div class="px-5">
      <ProductDetails :product="product" :save="isSave" :bundledItems="bundledItems" @createOrUpdateSupplier="createOrUpdateSupplier" @newBundleItems="addBundleItems"/>
    </div>
  </vs-card>

</template>

<script>
import { mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import axios from "@/axios";
import moment from "moment";
import ProductDetails from "./ProductDetails.vue"

export default {
  components: {
    Datepicker,
    ProductDetails
  },
  data() {
    return {
      showTickArray: [],
      loggedInUserType: "",
      suppliers: [],
      categories: [],
      roles: [],
      productTypes: ["Goods", "Training"],
      hasSelectedImages: false,
      hasMakeImageDefault: false,
      showVariantError: false,
      currency: process.env.VUE_APP_CURRENCY,
      outOfStock: false,
      isSave: false,
      bundledItems: [],
      product: {
        name: "",
        productName: "",
        description: "",
        price: "",
        productPrice: "",
        compareAtPrice: "",
        inventory: {
          managedBy: "",
          sku: "",
          requiredQuantity: 1,
        },
        shipping: {
          shippingFee: "",
          weight: "",
          dimensions: {
            length: "",
            width: "",
            height: "",
          },
        },
        manufacturedDate: "",
        expiryDate: "",
        productType: "Goods",
        visibleTo: "All",
        images: [],
        documents: [],
        variants: [],
        categoryId: [],
        isAvailable: true,
        s4Medication: false,
        isScript: false,
        isTaxable: false,
        tags: [],
        directionForUse: "",
        type: "basic",
        productTypeDescription: "",
        productTypeHeading: "",
        options: [{value: ""}],
        minQty: null,
        maxQty: null,
        minErrorMessage: "",
        maxErrorMessage: "",
        priceHistory: {
          nursePrice: null,
          drPrice: null
        },
        brandId: null,
        subDescription: "",
        promotionalDescription: "",
        listPrice: "",
        isCompareAtPriceToggled: false,
        isListPriceToggled: false,
        sageItemId: '',
        isRRPHidden: true,
        isListPriceHidden: true,
        amount: null,
        treatmentProduct: null,
        pricePerMeasurement: {
          price: "",
          unit: "",
        },
        isPricePerMeasurementHidden: false,
        hidden: true,
        supplierSKU: "",
        isCustomDeliveryFee: false,
        customDeliveryFee: 0,
        evolusBundle: {
          qty: null,
          additionalQty: null,
          productStoreId: null,
          isActive: false,
        },
        KAPrice: 320
      },
    };
  },
  methods: {
    ...mapActions("storeProducts", ["storeProduct", "addBundledItems"]),
    ...mapActions("ecommerce",["algoliaSync"]),
    createOrUpdateSupplier(data) {
      this.$vs.loading()
      this.storeProduct(data)
        .then(async(res) => {
          this.algoliaSync()
          if (res.data.data.type === "bundled-items" && this.bundledItems.length) {
            await this.createBundleItems(res.data.data._id)
          }
          let listRoute = "/super-admin/store/products";

          if (this.loggedInUserType === "admin")
            listRoute = "/admin/store/products";

          this.$vs.notify({
            title: "Success",
            text: "Product created successfully",
            color: "success",
          });

          this.$router.push(listRoute);
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Failed",
            text: err.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    cancelHandler() {
      if (this.loggedInUserType === "superAdmin") {
        this.$router.push("/super-admin/store/products");
      }
      if (user === "admin") {
        this.$router.push("/admin/store/products");
      }
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    makeImageDefault(imgUrl) {
      let tempArray = [];
      this.product.images.forEach((datum) => {
        if (datum.image === imgUrl) datum.useAsDefault = true;
        else datum.useAsDefault = false;
        tempArray.push(datum);
      });

      this.product.images = tempArray;
      this.hasMakeImageDefault = true;
    },
    makeVariantImageDefault(imgUrl, index) {
      let tempArray = [];
      this.product.variants[index].images.forEach((datum) => {
        if (datum.image === imgUrl) datum.useAsDefault = true;
        else datum.useAsDefault = false;
        tempArray.push(datum);
      });

      this.product.variants[index].images = tempArray;
    },
    onSave() {
      this.isSave = true;
      setTimeout(() => {
        this.isSave = false
      }, 1000)
    },
    addBundleItems(product){
      if(_.find(this.bundledItems,{productId:product._id})){
        this.$vs.notify({
          title: "Product Add",
          text: "Product has been already added below.",
          color: "danger",
        });
        return false;
      }
      this.bundledItems.push({
        productId: product._id,
        quantity: product.quantity || 1, // Set default quantity to 1
        name: product.name
      })
    },
    async createBundleItems(parentId) {
      try {
        const payload = this.bundledItems.map((item) => {
          return {
            parentProductId: parentId,
            productId: item.productId,
            quantity: item.quantity,
          }
        })
        await this.addBundledItems(payload);
      } catch (err) {
        console.error(err);
      }
    }
  },
  created() {
    this.loggedInUserType = this.$store.state.AppActiveUser.userType;
  },
};
</script>

<style scoped>
.custom-error {
  color: #ea5455;
  font-size: 0.65rem;
}

.vs-con-textarea {
  margin-bottom: 0 !important;
}

.section-wrapper {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 35px;
  margin-top: 20px;
}

.section-wrapper strong {
  font-size: 15px;
  text-transform: uppercase;
  position: absolute;
  top: -10px;
  left: 30px;
  background: #fff;
  padding: 0 10px;
}

.dateClass {
  margin-top: 16px;
  width: 93%;
  margin-left: auto;
  margin-right: auto;
}

.showImageDiv {
  align-items: center;
  justify-content: center;
  display: flex;
}

.register-img-wrapper {
  padding: 0;
  margin: 0 !important;
  margin-bottom: 5px !important;
}

.addMoreVariant {
  font-size: 13px;
  color: darkblue;
  cursor: pointer;
  float: right;
  margin-bottom: 10px;
  margin-right: 20px;
}

.removeVariant {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: darkred;
  font-size: 15px;
  font-weight: 900;
}

.to-uppercase input {
  text-transform: capitalize;
}

.non-accordion {
  margin-bottom: 20px;
  display: inline-block;
}

.child-category {
  margin-bottom: 20px;
}

.accordion-icon {
  float: right;
  background: #ddd;
  padding: 4px;
  margin-left: 27px !important;
  cursor: pointer;
}
</style>
